import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit, OnChanges {

  @Input() content: String = '';

  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.content) this.content = changes.content.currentValue;
  }

  back() {
    this.modalController.dismiss();
  }

  finish() {
    this.modalController.dismiss(this.content);
  }

}

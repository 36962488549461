import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComprobarImagenPipe } from './comprobar-imagen.pipe';
import { ImageSanitizerPipe } from './image-sanitizer.pipe';
import { DomSanitizerPipe } from './dom-sanitizer.pipe';
import { NumberFormatPipe } from './number-format.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { ImagePipe } from './image.pipe';
import { RoundPipe } from './round.pipe';

@NgModule({
  declarations: [
    ComprobarImagenPipe,
    ImageSanitizerPipe,
    DomSanitizerPipe,
    NumberFormatPipe,
    FormatDatePipe,
    ImagePipe,
    RoundPipe,
  ],
  exports: [
    ComprobarImagenPipe,
    ImageSanitizerPipe,
    NumberFormatPipe,
    DomSanitizerPipe,
    FormatDatePipe,
    ImagePipe,
    RoundPipe,
  ]
})
export class PipesModule { }

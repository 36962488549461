import { Pipe, PipeTransform } from '@angular/core';

import { environment } from 'src/environments/environment';

const API = environment.host;

@Pipe({
  name: 'formatearImagen'
})
export class ImagePipe implements PipeTransform {

  transform(imagen: any): string {
    let url = 'assets/images/no-image.jpg';

    if (imagen) {
      let aux = imagen.url ? imagen.url : imagen;
      if (aux.indexOf('http') == -1) url = `${API}${aux}`;
      else url = aux;
    }

    return url;
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-range-container {
  position: relative;
}

.today {
  right: 0;
  top: -53px;
  display: flex;
  max-width: 80px;
  position: absolute;
  align-items: center;
  justify-content: space-between;
}
.today label {
  cursor: pointer;
  margin-right: 10px;
}
.today ion-checkbox {
  border-color: white;
  --background: white;
}

.date-picker-header {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.date-picker-header label {
  cursor: pointer;
}
.date-picker-header ion-button {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/date-range/date-range.component.scss"],"names":[],"mappings":"AACA;EACI,kBAAA;AAAJ;;AAGA;EACI,QAAA;EACA,UAAA;EACA,aAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,8BAAA;AAAJ;AAEI;EACI,eAAA;EACA,kBAAA;AAAR;AAGI;EACI,mBAAA;EACA,mBAAA;AADR;;AAKA;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;AAFJ;AAII;EACI,eAAA;AAFR;AAKI;EACI,iBAAA;AAHR","sourcesContent":["\n.date-range-container {\n    position: relative;\n}\n\n.today {\n    right: 0;\n    top: -53px;\n    display: flex;\n    max-width: 80px;\n    position: absolute;\n    align-items: center;\n    justify-content: space-between;\n\n    label {\n        cursor: pointer;\n        margin-right: 10px;\n    }\n\n    ion-checkbox {\n        border-color: white;\n        --background: white;\n    }\n}\n\n.date-picker-header {\n    display: flex;\n    align-items: center;\n    margin-top: 10px;\n\n    label {\n        cursor: pointer;\n    }\n\n    ion-button {\n        margin-left: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import Viewer from 'viewerjs';

@Component({
  selector: 'app-news-slides',
  templateUrl: './news-slides.component.html',
  styleUrls: ['./news-slides.component.scss'],
})
export class NewsSlidesComponent implements OnInit {

  @Input() news;

  slideOpts = {
    // loop: true,
    // initialSlide: 0,
    speed: 200,
    autoplay: true,
    slidesPerView: 1.2,
    breakpoints: {
      0: {
        slidesPerView: 1.2,
        spaceBetween: 4,
      },
      320: {
        slidesPerView: 1.2,
        spaceBetween: 4,
      },
      480: {
        slidesPerView: 1.2,
        spaceBetween: 4,
      },
      640: {
        slidesPerView: 2.2,
        spaceBetween: 4,
      },
      767: {
        slidesPerView: 3.2,
        spaceBetween: 4,
      },
      1024: {
        slidesPerView: 3.2,
        spaceBetween: 4,
      }
    }
  };

  constructor(
    private router: Router
  ) { }

  ngOnInit() {}

  previewImage(publicidad) {
    const viewer = new Viewer(document.getElementById(`preview-${publicidad.id}`), {
      inline: false,
      viewed() {
        viewer.zoomTo(0.5);
      },
    });
    // if (this.platform.is('desktop')) {
    // } else if (publicidad.imagen && publicidad.imagen.includes('_capacitor_file_')) {
    //   this.generalService.previewImage(publicidad.imagen);
    // }
  }

  redirectTo(noticia) {
    this.router.navigate(['/tabs/noticia', noticia.id ])
  }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep editor div[role=application] {
  min-height: 93vh;
  border-radius: 0;
}

ion-button {
  --box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/editor/editor.component.scss"],"names":[],"mappings":"AAEI;EACI,gBAAA;EACA,gBAAA;AADR;;AAKA;EACI,kBAAA;AAFJ","sourcesContent":["\n:host ::ng-deep {\n    editor div[role=\"application\"] {\n        min-height: 93vh;\n        border-radius: 0;\n    }\n}\n\nion-button {\n    --box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private toastController: ToastController
  ) { }

  async toast(message: string, duration: number = 2000) {
    const toast = await this.toastController.create({
      message,
      duration,
      cssClass: 'custom-toast'
    });

    await toast.present();
  }
}

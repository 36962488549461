// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-user {
  width: 100%;
  --background: #f6f6f6;
  box-shadow: none;
  margin-bottom: 0px;
}
.card-user ion-card-content {
  padding: 0px;
  color: white;
  background-color: var(--ion-color-secondary);
}
.card-user ion-text {
  display: flex;
  align-items: center;
}
.card-user h2 {
  margin: 0px;
}
.card-user ion-button {
  --padding-start: 0.7em;
  --padding-end: 0.7em;
  --border-color: transparent;
}
.card-user ion-icon {
  font-size: 15px;
}
.card-user .fecha-user {
  font-size: 11px;
}
.card-user .user-name {
  font-size: 20px;
}

ion-avatar {
  border: 2px solid red;
}
ion-avatar.payed {
  border: 2px solid green;
}`, "",{"version":3,"sources":["webpack://./src/app/components/card-user/card-user.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,qBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACI;EACI,YAAA;EACA,YAAA;EACA,4CAAA;AACR;AAEI;EACI,aAAA;EACA,mBAAA;AAAR;AAGI;EACI,WAAA;AADR;AAII;EACI,sBAAA;EACA,oBAAA;EACA,2BAAA;AAFR;AAKI;EACI,eAAA;AAHR;AAMI;EACI,eAAA;AAJR;AAOI;EACI,eAAA;AALR;;AASA;EACI,qBAAA;AANJ;AAQI;EACI,uBAAA;AANR","sourcesContent":[".card-user {\n    width: 100%;\n    --background: #f6f6f6;\n    box-shadow: none;\n    margin-bottom: 0px;\n\n    ion-card-content {\n        padding: 0px;\n        color: white;\n        background-color: var(--ion-color-secondary);\n    }\n\n    ion-text {\n        display: flex;\n        align-items: center;\n    }\n\n    h2 {\n        margin: 0px;\n    }\n\n    ion-button {\n        --padding-start: 0.7em;\n        --padding-end: 0.7em;\n        --border-color: transparent;\n    }\n\n    ion-icon {\n        font-size: 15px;\n    }\n\n    .fecha-user {\n        font-size: 11px;\n    }\n\n    .user-name {\n        font-size: 20px;\n    }\n}\n\nion-avatar {\n    border: 2px solid red;\n\n    &.payed {\n        border: 2px solid green;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

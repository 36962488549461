// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.images {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.images .image {
  width: 100%;
  height: 150px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/preview-images/preview-images.component.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,6BAAA;EACA,eAAA;AAAJ;AAEI;EACI,WAAA;EACA,aAAA;EACA,4BAAA;EACA,2BAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;AAAR","sourcesContent":["\n.images {\n    display: flex;\n    justify-content: space-around;\n    flex-wrap: wrap;\n\n    .image {\n        width: 100%;\n        height: 150px;\n        background-repeat: no-repeat;\n        background-position: center;\n        background-size: cover;\n        border-radius: 10px;\n        margin-top: 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

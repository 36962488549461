import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

const API = environment.api;

@Injectable({
  providedIn: 'root'
})
export class AsistenciaService {

  key: any;
  token: any;
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  // Obtener asistencias
  list(query = {}) {
    return new Promise(resolve => {
      const params = new HttpParams({ fromObject: query });
      this.http.get(`${API}/asistencia`, { params })
        .subscribe(
          (response: any) => resolve(response),
          error => resolve(error)
        );
    });
  }
  
  // Obtener asistencias
  listPorcentaje(query = {}) {
    return new Promise(resolve => {
      const params = new HttpParams({ fromObject: query });
      this.http.get(`${API}/porcentaje`, { params })
        .subscribe(
          (response: any) => resolve(response),
          error => resolve(error)
        );
    });
  }

  // Obtener asistencia
  get(id) {
    return new Promise(resolve => {
      this.http.get(`${API}/asistencia/${id}`)
        .subscribe(
          (response: any) => resolve(response),
          error => resolve(error)
        );
    });
  }
  
  //obtener estadistica
  
  estadistica(data) {
    return new Promise(resolve => {
      this.http.get(`${API}/asistencia/${data}`)
        .subscribe(
          (response: any) => resolve(response),
          error => resolve(error)
        );
    });
  }
  

  // Crear asistencia
  crear(data) {
    return new Promise(resolve => {
      this.http.post(`${API}/asistencia`, data)
        .subscribe(
          (response: any) => resolve(response),
          error => resolve(error)
        );
    });
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-button {
  width: 100%;
  --border-radius: 20px;
}
ion-button ion-icon {
  margin: 0;
  font-size: 38px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/select-image/select-image.component.scss"],"names":[],"mappings":"AACA;EAEI,WAAA;EACA,qBAAA;AADJ;AAGI;EACI,SAAA;EACA,eAAA;AADR","sourcesContent":["\nion-button {\n    // --padding: 3em;\n    width: 100%;\n    --border-radius: 20px;\n\n    ion-icon {\n        margin: 0;\n        font-size: 38px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

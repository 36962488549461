import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'domSanitizer'
})
export class DomSanitizerPipe implements PipeTransform {

  constructor (private domSanitizer: DomSanitizer) {}

  transform(url: string, type: string): any {
    switch (type) {
      case 'resource':
        url = url.replace('watch?v=', 'embed/');
        url = url.replace('shorts', 'embed');
        url = url.replace('youtu.be/', 'youtube.com/embed/');
        url = url.replace('youtube.com/live/', 'youtube.com/embed/');
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
      default:
        return this.domSanitizer.bypassSecurityTrustStyle(url);
    }
  }

}

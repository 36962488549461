// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  background: white;
  border-radius: 50%;
}
ion-content .unpaid-container {
  display: flex;
  min-height: 90vh;
  padding: 2em;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}
ion-content .unpaid-container .message-container {
  text-align: justify;
}`, "",{"version":3,"sources":["webpack://./src/app/components/unpaid/unpaid.component.scss"],"names":[],"mappings":"AACA;EACI,iBAAA;EACA,kBAAA;AAAJ;AAEI;EACI,aAAA;EACA,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,sBAAA;EACA,6BAAA;AAAR;AAEQ;EACI,mBAAA;AAAZ","sourcesContent":["\nion-content {\n    background: white;\n    border-radius: 50%;\n\n    .unpaid-container {\n        display: flex;\n        min-height: 90vh;\n        padding: 2em;\n        align-items: center;\n        flex-direction: column;\n        justify-content: space-evenly;\n\n        .message-container {\n            text-align: justify;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, OnInit, Output, EventEmitter, Input, ViewChild, OnChanges } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { Platform } from '@ionic/angular';

import { GeneralService } from 'src/app/services/general.service';

declare var window: any;

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss'],
})
export class ProfilePictureComponent implements OnInit {

  @Input() path: string;
  @Output() processedImage = new EventEmitter();

  @ViewChild('inputFile') inputFile;

  constructor(
    private actionSheetController: ActionSheetController,
    private generalService: GeneralService,
    private platform: Platform,
    private camera: Camera,
  ) { }

  ngOnInit() {}

  fileChanged(event) {
    if (event.target.files.length) {
      const [ file ] = event.target.files;
      this.path = URL.createObjectURL(file);
      this.processedImage.emit({ path: this.path, blob: file });
    }
  }

  async chooseOption() {
    let buttons = [
      {
        text: 'Abrir Camara',
        icon: 'camera-outline',
        handler: () => this.openCamera()
      },
      {
        text: 'Abrir Galería',
        icon: 'image-outline',
        handler: () => this.openGallery()
      },
    ];

    if (this.platform.is("desktop") || this.platform.is("mobileweb")) {
      buttons = [{
        text: 'Seleccionar imagen',
        icon: 'image-outline',
        handler: () => this.inputFile && this.inputFile.nativeElement.click()
      }];
    }

    const actionSheet = await this.actionSheetController.create({
      buttons
    });

    return await actionSheet.present();
  }

  openCamera() {
    const options: CameraOptions = {
      quality: 60,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      sourceType: this.camera.PictureSourceType.CAMERA
    };

    this.processImage(options);
  }

  openGallery() {
    const options: CameraOptions = {
      quality: 60,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY
    };

    this.processImage(options);
  }

  processImage(options: CameraOptions) {
    this.camera.getPicture(options).then(async (imageData) => {
      const blob = await this.generalService.resolveLocalImageFile(imageData);
      this.path = window.Ionic.WebView.convertFileSrc(imageData);
      this.processedImage.emit({ path: this.path, blob});
    }, (err) => {
      // Handle error
    });
  }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `swiper-slide {
  background-color: white;
  border-radius: 20px;
}
swiper-slide .image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 20px;
  min-height: 130px;
  max-height: 130px;
  height: 150px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/brand-slides/brand-slides.component.scss"],"names":[],"mappings":"AAAA;EAEI,uBAAA;EACA,mBAAA;AAAJ;AAEI;EACI,4BAAA;EACA,2BAAA;EACA,wBAAA;EACA,mBAAA;EACA,iBAAA;EACA,iBAAA;EACA,aAAA;EACA,WAAA;AAAR","sourcesContent":["swiper-slide {\n    // flex-direction: column;\n    background-color: white;\n    border-radius: 20px;\n\n    .image {\n        background-repeat: no-repeat;\n        background-position: center;\n        background-size: contain;\n        border-radius: 20px;\n        min-height: 130px;\n        max-height: 130px;\n        height: 150px;\n        width: 100%;\n    }\n\n    // .content {\n    //     width: 100%;\n    //     margin-top: 0.4em;\n\n    //     .date, .title {\n    //         text-align: left;\n    //         color: #7e7e7e;\n    //     }\n\n    //     .date {\n    //         color: #7e7e7e;\n    //         font-size: 14px;\n    //     }\n    // }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

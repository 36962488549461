import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';

import { GeneralService } from 'src/app/services/general.service';
import { RutinaService } from 'src/app/services/rutina.service';

@Component({
  selector: 'app-detalle-rutina',
  templateUrl: './detalle-rutina.component.html',
  styleUrls: ['./detalle-rutina.component.scss'],
})
export class DetalleRutinaComponent implements OnInit {

  tipoRutina = {
    1: 'Para todos',
    2: 'Por usuario'
  };

  tipoFecha = {
    1: 'Fecha especifica',
    2: 'Rango de fechas',
    3: 'Por día',
  };

  @Input() rutina;

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private generalService: GeneralService,
    private rutinaService: RutinaService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {}

  getDays(detalle) {
    const days = this.generalService.getDays();
    const dayStr = detalle.dias.split(";").map(index => days[index]);
    return dayStr.join(' - ');
  }

  async confirmToRemove(detail) {
    const alert = await this.alertController.create({
      header: 'Eliminar detalle de rutina',
      subHeader: '¿Estas seguro de continuar?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => { }
        },
        {
          text: 'Aceptar',
          handler: () => this.removeDetail(detail)
        }
      ]
    })

    return await alert.present();
  }

  async removeDetail(detail) {
    const response: any = await this.rutinaService.removeDetail(this.rutina.id, detail.id);
    if (response.success) {
      this.rutina.detalles = this.rutina.detalles.filter(detalle => detalle.id != detail.id);
      this.alertService.toast('Eliminado correctamente');
      return;
    }

    this.alertService.toast('Ha ocurrido un problema, por favor intentelo más tarde');
  }

  close() {
    this.modalController.dismiss();
  }

}

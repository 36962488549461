import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import Viewer from 'viewerjs';

@Component({
  selector: 'app-brand-slides',
  templateUrl: './brand-slides.component.html',
  styleUrls: ['./brand-slides.component.scss'],
})
export class BrandSlidesComponent implements OnInit {

  @Input() brands;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {}

  previewImage(publicidad) {
    const viewer = new Viewer(document.getElementById(`preview-${publicidad.id}`), {
      inline: false,
      viewed() {
        viewer.zoomTo(0.5);
      },
    });
  }

  redirectTo(publicida) {
    this.router.navigate(['/tabs/marcas', publicida.id ])
  }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected-news {
  display: flex;
  align-items: flex-end;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 220px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.selected-news .info {
  color: rgb(1, 22, 80);
  padding: 1em;
  text-shadow: -1px 0 rgba(29, 12, 12, 0.7);
}
.selected-news .info .title {
  font-weight: bold;
  font-size: 26px;
  color: #7e7e7e;
}
.selected-news .info .description {
  font-size: 18px;
}

swiper-slide {
  flex-direction: column;
}
swiper-slide .image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  min-height: 130px;
  max-height: 130px;
  height: 150px;
  width: 100%;
}
swiper-slide .content {
  width: 100%;
  margin-top: 0.4em;
}
swiper-slide .content .date, swiper-slide .content .title {
  text-align: left;
  color: white;
}
swiper-slide .content .date {
  color: white;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/news-slides/news-slides.component.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,qBAAA;EACA,4BAAA;EACA,2BAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AAAJ;AAEI;EACI,qBAAA;EACA,YAAA;EACA,yCAAA;AAAR;AAEQ;EACI,iBAAA;EACA,eAAA;EACA,cAAA;AAAZ;AAGQ;EACI,eAAA;AADZ;;AAMA;EACI,sBAAA;AAHJ;AAKI;EACI,4BAAA;EACA,2BAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA;EACA,iBAAA;EACA,aAAA;EACA,WAAA;AAHR;AAMI;EACI,WAAA;EACA,iBAAA;AAJR;AAMQ;EACI,gBAAA;EACA,YAAA;AAJZ;AAOQ;EACI,YAAA;EACA,eAAA;AALZ","sourcesContent":["\n.selected-news {\n    display: flex;\n    align-items: flex-end;\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: cover;\n    height: 220px;\n    border-radius: 10px;\n    margin-bottom: 10px;\n\n    .info {\n        color: rgb(1, 22, 80);\n        padding: 1em;\n        text-shadow: -1px 0 rgba(29, 12, 12, 0.7);\n\n        .title {\n            font-weight: bold;\n            font-size: 26px;\n            color: #7e7e7e;\n        }\n\n        .description {\n            font-size: 18px;\n        }\n    }\n}\n\nswiper-slide {\n    flex-direction: column;\n\n    .image {\n        background-repeat: no-repeat;\n        background-position: center;\n        background-size: cover;\n        border-radius: 20px;\n        min-height: 130px;\n        max-height: 130px;\n        height: 150px;\n        width: 100%;\n    }\n\n    .content {\n        width: 100%;\n        margin-top: 0.4em;\n\n        .date, .title {\n            text-align: left;\n            color: white;\n        }\n\n        .date {\n            color: white;\n            font-size: 14px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header ion-button {
  --box-shadow: none;
}

.header-container {
  padding: 0 1em;
}

ion-avatar {
  margin: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/search-users/search-users.component.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;AAAR;;AAIA;EACI,cAAA;AADJ;;AAIA;EACI,cAAA;AADJ","sourcesContent":["ion-header {\n    ion-button {\n        --box-shadow: none;\n    }\n}\n\n.header-container {\n    padding: 0 1em;\n}\n\nion-avatar {\n    margin: 0 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { EventEmitter, Injectable, Output } from '@angular/core';
import { LoadingController } from '@ionic/angular';

import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { File, FileEntry } from '@awesome-cordova-plugins/file/ngx';

import { AlertService } from './alert.service';
import { FormGroup, Validators } from '@angular/forms';

// const API = environment.api;

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  @Output() unpaid: EventEmitter<any> = new EventEmitter();
  @Output() removeImage: EventEmitter<any> = new EventEmitter();
  @Output() limpiarImagenes: EventEmitter<any> = new EventEmitter();

  private loading;

  constructor(
    private loadingController: LoadingController,
    private alertService: AlertService,
    private photoViewer: PhotoViewer,
    private file: File,
  ) { }

  createQueue(tasks, callback) {
    let taskIndex = 0;

    return new Promise(done => {
      const handleResult = result => {
        taskIndex++;
        getNextTask();
      };
      const getNextTask = async () => {
        if (taskIndex < tasks.length) {
          const element = tasks[taskIndex];
          const response = await callback(element);
          handleResult(response);
        } else {
          done({ success: true });
        }
      };
      getNextTask();
    });
  }

  previewImage(url) {
    this.photoViewer.show(url);
  }

  async showLoading(message = 'Cargando...') {
    this.loading = await this.loadingController.create({
      message
    });

    await this.loading.present();
  }

  getDays() {
    return [
      'Domingo',
      'Lunes',
      'Martes',
      'Miercoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ];
  }

  hideLoading() {
    this.loading?.dismiss();
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  resolveLocalImageFile(path) {
    return new Promise(async (resolve) => {
      await this.file.resolveLocalFilesystemUrl(path)
      .then(entry => {
        (entry as FileEntry).file(async (file) => {
          const blob = await this.readImage(file);
          return resolve(blob);
        });
      })
      .catch(err => {
        console.log('err: ', err);
        this.alertService.toast('Ha ocurrido un error al leer el archivo.', 3000);
        return resolve(null);
      });
    });
  }

  getFileReader(): FileReader {
    const fileReader = new FileReader();
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const zoneOriginalInstance = (fileReader as any)['__zone_symbol__originalInstance'];
    return zoneOriginalInstance || fileReader;
  }

  toggleFullScreen(element) {
    const doc = window.document as any;

    const requestFullScreen =
      element.requestFullscreen ||
      element.mozRequestFullScreen ||
      element.webkitRequestFullScreen ||
      element.msRequestFullscreen;

    const cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(element);
    } else {
      cancelFullScreen.call(doc);
    }
  }

  getProfileFormFields(type = 'cross') {
    const formFields = {
      cross: [
        { label: 'Peso', field: 'peso' },
        { label: 'Altura', field: 'altura' },
        { label: 'Masa muscular', field: 'masa_muscular' },
        { label: 'Grasa corporal', field: 'cant_grasa' },
      ],
      functional: [
        { label: 'Peso', field: 'peso' },
        { label: 'Altura', field: 'altura' },
        { label: 'Masa muscular', field: 'masa_muscular' },
        { label: 'Grasa corporal', field: 'cant_grasa' },
        { label: 'Hombo', field: 'hombro' },
        { label: 'Cintura A', field: 'cintura_A' },
        { label: 'Cintura M', field: 'cintura_M' },
        { label: 'Cintura B', field: 'cintura_B' },
        { label: 'Gluteos', field: 'gluteos' },
        { label: 'Muslo', field: 'muslo' },
        { label: 'Sentadilla Frontal', field: 'sent_frontal' },
        { label: 'Sentadilla Lateral', field: 'sent_lateral' },
        { label: 'Sentadilla Posterior', field: 'sent_posterior' },
        { label: 'Test tobillo', field: 'test_tobillo' },
        { label: 'Test cadera', field: 'test_cadera' },
        { label: 'Test superiores', field: 'test_superiores' },
      ]
    };

    return formFields[type];
  }

  requiredValidator(masterControlLabel: string, operator: string, conditionalValue: any, slaveControlLabel: string) {
    return (group: FormGroup): { [key: string]: any } => {
      const masterControl = group.controls[masterControlLabel];
      const slaveControl = group.controls[slaveControlLabel];
      if (eval(`'${masterControl.value}' ${operator} '${conditionalValue}'`) || !masterControl.value) {
        return Validators.required(slaveControl);
      }
      slaveControl.setErrors(null);
      return null;
    };
  }

  minValueValidator(masterControlLabel: string, operator: string, conditionalValue: any, slaveControlLabel: string, minValue: number) {
    return (group: FormGroup): { [key: string]: any } => {
      const masterControl = group.controls[masterControlLabel];
      const slaveControl = group.controls[slaveControlLabel];
      if (eval(`'${masterControl.value}' ${operator} '${conditionalValue}'`)) {
        return Validators.min(minValue);
      }
      slaveControl.setErrors(null);
      return null;
    };
  }

  private async readImage(file: any) {
    return new Promise(resolve => {
      const reader = this.getFileReader();
      reader.onloadend = () => {
        const blobImg = new Blob([reader.result], { type: file.type });
        return resolve(blobImg);
      };
      reader.readAsArrayBuffer(file);
    });
  }

}

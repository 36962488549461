// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.picture-container {
  position: relative;
}
.picture-container .picture {
  display: flex;
  width: 250px;
  margin: auto;
  height: 250px;
  border-radius: 50%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border: 1px solid #9c9a9a;
  background-position: center;
}
.picture-container .picture ion-img {
  width: 150px;
  height: 150px;
}
.picture-container ion-button {
  width: 56px;
  height: 56px;
  --padding-start: 0;
  --padding-end: 0;
  --border-radius: 50%;
  position: absolute;
  right: 60px;
  bottom: 0;
}

input[type=file] {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/profile-picture/profile-picture.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AACI;EACI,aAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,uCAAA;EACA,iCAAA;EACA,yBAAA;EACA,2BAAA;AACR;AACQ;EACI,YAAA;EACA,aAAA;AACZ;AAGI;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;AADR;;AAKA;EACI,aAAA;AAFJ","sourcesContent":[".picture-container {\n    position: relative;\n\n    .picture {\n        display: flex;\n        width: 250px;\n        margin: auto;\n        height: 250px;\n        border-radius: 50%;\n        background-repeat: no-repeat !important;\n        background-size: cover !important;\n        border: 1px solid #9c9a9a;\n        background-position: center;\n\n        ion-img {\n            width: 150px;\n            height: 150px;\n        }\n    }\n\n    ion-button {\n        width: 56px;\n        height: 56px;\n        --padding-start: 0;\n        --padding-end: 0;\n        --border-radius: 50%;\n        position: absolute;\n        right: 60px;\n        bottom: 0;\n    }\n}\n\ninput[type=file] {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep thead th {
  background: var(--ion-color-primary) !important;
}
:host ::ng-deep thead th a {
  color: white;
}
:host ::ng-deep td.monthview-current,
:host ::ng-deep td.monthview-selected {
  background: transparent !important;
}
:host ::ng-deep td.monthview-primary-with-event {
  background: #009900 !important;
}
:host ::ng-deep .event-detail-container {
  display: none;
}
:host ::ng-deep .fc-toolbar-title,
:host ::ng-deep .fc-button-group {
  font-size: 1em;
}
:host ::ng-deep .fc-daygrid-day-events {
  display: none;
}
:host ::ng-deep td.fc-daygrid-day:has(.fc-event-start) {
  background: var(--ion-color-primary);
}
:host ::ng-deep td.fc-daygrid-day:has(.fc-event-start) .fc-daygrid-day-number {
  color: white;
}

.container-fluid {
  padding: 1em;
  color: white;
}
.container-fluid .date-title {
  font-size: 1.2rem;
  padding: 0 0.5em 0.8em 0.5em;
}
.container-fluid .loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/calendar/calendar.component.scss"],"names":[],"mappings":"AAEI;EACI,+CAAA;AADR;AAGQ;EACI,YAAA;AADZ;AAKI;;EAEI,kCAAA;AAHR;AAMI;EAEI,8BAAA;AALR;AAQI;EACI,aAAA;AANR;AASI;;EAEI,cAAA;AAPR;AAUI;EACI,aAAA;AARR;AAWI;EACI,oCAAA;AATR;AAWQ;EACI,YAAA;AATZ;;AAcA;EACI,YAAA;EACA,YAAA;AAXJ;AAaI;EACI,iBAAA;EACA,4BAAA;AAXR;AAcI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AAZR","sourcesContent":["\n:host ::ng-deep {\n    thead th {\n        background: var(--ion-color-primary) !important;\n\n        a {\n            color: white;\n        }\n    }\n\n    td.monthview-current,\n    td.monthview-selected {\n        background: transparent !important;\n    }\n\n    td.monthview-primary-with-event {\n        // background: var(--ion-color-primary) !important;\n        background: #009900 !important;\n    }\n\n    .event-detail-container {\n        display: none;\n    }\n\n    .fc-toolbar-title,\n    .fc-button-group {\n        font-size: 1em;\n    }\n\n    .fc-daygrid-day-events {\n        display: none;\n    }\n\n    td.fc-daygrid-day:has(.fc-event-start) {\n        background: var(--ion-color-primary);\n\n        .fc-daygrid-day-number {\n            color: white;\n        }\n    }\n}\n\n.container-fluid {\n    padding: 1em;\n    color: white;\n\n    .date-title {\n        font-size: 1.2rem;\n        padding: 0 0.5em 0.8em 0.5em;\n    }\n\n    .loading-container {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

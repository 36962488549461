import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

import * as moment from 'moment';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {

  @Input() events = [];
  @Output() dateChange: EventEmitter<any> = new EventEmitter();

  calendar;
  isToday: boolean;
  loadingCalendar = true;

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin],
    initialView: 'dayGridMonth',
    weekends: true,
    locale: 'es-Es',
    events: [],
    datesSet: this.handleMonthChange.bind(this),
    showNonCurrentDates: false
  };

  constructor() { }

  ngOnInit() {
    this.loadEvents();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadingCalendar = false;
    }, 4000);
  }

  ngOnChanges(changes) {
    if (changes.events) {
      this.events = changes.events.currentValue;
      this.loadEvents();
    }
  }

  loadEvents() {
    this.calendarOptions.events = this.createEvents();
  }

  async handleMonthChange(payload) {
    this.dateChange.emit(payload.startStr);
  }

  onCurrentDateChanged(event: Date) {
    let today = moment().toDate();
    today.setHours(0, 0, 0, 0);
    event.setHours(0, 0, 0, 0);
    this.isToday = today.getTime() === event.getTime();
    this.dateChange.emit(event);
  }

  createEvents() {
    let events = [];

    this.events.map(event => {
      let start = moment(`${event.fecha} 10:00`, 'YYYY-MM-DD HH:mm').toDate();
      let end = moment(`${event.fecha} 11:00`, 'YYYY-MM-DD HH:mm').toDate();

      events.push({
        title: `Asistencia - ${event.fecha}`,
        start,
        end,
        allDay: false
      });
    });

    return events;
  }

}

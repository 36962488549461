// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header ion-button {
  --box-shadow: none;
}

ion-card {
  position: relative;
}
ion-card .remove {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.2rem;
  padding: 0.8em;
}`, "",{"version":3,"sources":["webpack://./src/app/components/detalle-rutina/detalle-rutina.component.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;AAAR;;AAIA;EACI,kBAAA;AADJ;AAGI;EACI,kBAAA;EACA,MAAA;EACA,QAAA;EACA,iBAAA;EACA,cAAA;AADR","sourcesContent":["ion-header {\n    ion-button {\n        --box-shadow: none;\n    }\n}\n\nion-card {\n    position: relative;\n\n    .remove {\n        position: absolute;\n        top: 0;\n        right: 0;\n        font-size: 1.2rem;\n        padding: 0.8em;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { StorageService } from './storage.service';

const API = environment.api;

@Injectable({
  providedIn: 'root'
})
export class RutinaService {

  key: any;
  token: any;

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  // Obtener rutinas
  list(query = {}) {
    return new Promise(resolve => {
      const params = new HttpParams({ fromObject: query })
      this.http.get(`${API}/rutinas`, { params })
        .subscribe(
          (response: any) => resolve(response),
          error => resolve(error)
        );
    });
  }

  search(query = {}) {
    return new Promise(resolve => {
      const params = new HttpParams({ fromObject: query })
      this.http.get(`${API}/buscar-rutina`, { params })
        .subscribe(
          (response: any) => resolve(response),
          error => resolve(error)
        );
    });
  }

  // Obtener rutina
  get(id) {
    return new Promise(resolve => {
      this.http.get(`${API}/rutinas/${id}`)
        .subscribe(
          (response: any) => resolve(response),
          error => resolve(error)
        );
    });
  }

  // Crear rutinas
  crear(data) {
    return new Promise(resolve => {
      this.http.post(`${API}/rutinas`, data)
        .subscribe(
          (response: any) => resolve(response),
          error => resolve(error)
        );
    });
  }

  // Actualizar rutinas
  update(data) {
    return new Promise(resolve => {
      this.http.put(`${API}/rutinas/${data.id}`, data)
        .subscribe(
          (response: any) => resolve(response),
          error => resolve(error)
        );
    });
  }

  // Crear rutinas
  crearPerUser(data) {
    return new Promise(resolve => {
      this.http.post(`${API}/rutina_user`, data)
        .subscribe(
          (response: any) => resolve(response),
          error => resolve(error)
        );
    });
  }

  // Actualizar rutinas
  updatePerUser(data) {
    return new Promise(resolve => {
      this.http.put(`${API}/rutina_user/${data.id}`, data)
        .subscribe(
          (response: any) => resolve(response),
          error => resolve(error)
        );
    });
  }

  removeDetail(idRutina, idDetalle) {
    return new Promise(resolve => {
      this.http.delete(`${API}/rutinas/${idRutina}/detalle/${idDetalle}`)
        .subscribe(
          (response: any) => resolve(response),
          error => resolve(error)
        );
    });
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-search-users',
  templateUrl: './search-users.component.html',
  styleUrls: ['./search-users.component.scss'],
})
export class SearchUsersComponent implements OnInit {

  user;
  idUsuario;
  loading = false;
  currentPage = 1;
  usuarios: any[] = [];
  scrolling: boolean = false;
  infScrollDisabled = false;

  @Input() isSelectable;

  constructor(
    private modalController: ModalController,
    private alertService: AlertService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
  ) {
    this.getUsers(null, {}, false);
  }

  async ionViewWillEnter() {
    // obtener datos del usuario desde el servicio y asignar al formulario
    this.user = await this.authService.getUser();
    if (!this.loading) this.getUsers(null, {}, false);
  }

  ngOnInit() {
    if (!this.loading) this.getUsers(null, {}, false);
  }

  ionInfinite(event) {
    this.currentPage++;
    this.scrolling = true;
    this.getUsers(event);
  }

  async getUsers(event?, query = {}, push = true) {

    this.loading = true;

    query['page'] = this.currentPage;

    const response: any = await this.userService.list(query);
    if (response.success) {
      if (push) this.usuarios.push(...response.data);
      else this.usuarios = response.data;

      if (response.data.length == 0) this.infScrollDisabled = true;
    }

    if (event) {
      try { event.target.complete(); }
      catch(e) {}
    }

    this.loading = false;
  }

  buscar(event) {
    this.currentPage = 1;
    let value = event.detail.value;
    if (value.length > 3) {
      let query = { name: value, email: value };
      this.getUsers(null, query);
    } else if (value == '') this.getUsers();
  }

  makeAction(user) {
    if (this.isSelectable) this.toggleSelection(user);
    else this.selectUser(user);
  }

  selectUser(user) {
    this.modalController.dismiss(user);
  }

  toggleSelection(user) {
    user['selected'] = !user['selected'];
  }

  finish() {
    const users = this.usuarios.filter(user => user.selected);
    this.modalController.dismiss(users);
  }

  refresh(event) {
    this.usuarios = [];
    this.currentPage = 1;
    this.infScrollDisabled = false;
    this.getUsers(event);
  }

  close() {
    this.modalController.dismiss();
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header ion-toolbar {
  padding: 0.5em 0.5em;
  --background: transparent;
}
ion-header ion-menu-button {
  /* --background: rgba(119, 100, 100, 0.3);
  --border-radius: 10px; */
  --color: white;
}
ion-header ion-title {
  color: white;
  text-align: center;
  font-weight: bold;
}
ion-header ion-avatar {
  width: 32px;
  height: 32px;
}
ion-header .profile {
  --padding-end: 0.4em;
  --padding-start: 0.4em;
  --box-shadow: none;
  font-size: 20px;
  height: 32px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header.component.scss"],"names":[],"mappings":"AAGI;EACI,oBAAA;EACA,yBAAA;AAFR;AAKI;EACI;0BAAA;EAEA,cAAA;AAHR;AAMI;EACI,YAAA;EACA,kBAAA;EACA,iBAAA;AAJR;AAOI;EACI,WAAA;EACA,YAAA;AALR;AAQI;EACI,oBAAA;EACA,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;AANR","sourcesContent":["\nion-header {\n\n    ion-toolbar {\n        padding: 0.5em 0.5em;\n        --background: transparent;\n    }\n\n    ion-menu-button {\n        /* --background: rgba(119, 100, 100, 0.3);\n        --border-radius: 10px; */\n        --color: white;\n    }\n\n    ion-title {\n        color: white;\n        text-align: center;\n        font-weight: bold;\n    }\n\n    ion-avatar {\n        width: 32px;\n        height: 32px;\n    }\n\n    .profile {\n        --padding-end: 0.4em;\n        --padding-start: 0.4em;\n        --box-shadow: none;\n        font-size: 20px;\n        height: 32px;\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
